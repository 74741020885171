<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <v-card>
      <v-card-title>Atenção</v-card-title>

      <v-card-text>



        <v-alert v-if="hasCustomer && overdueTotal > 0" border="left" type="error" dense outlined>
          Total Vencido: {{ $format.moneyBr(overdueTotal) }}
        </v-alert>
        <v-alert v-if="hasCustomer && toPayTotal > 0" border="left" type="error" dense outlined>
          Total a receber: {{ $format.moneyBr(toPayTotal) }}
        </v-alert>

        <template v-if="hasNegotiation">
          <v-row>
            <v-col>
              <h4>
                <v-icon left>mdi-information</v-icon>
                Informações da Negociação
              </h4>

              <div class="d-flex flex-column ml-9">
                <span>
                  <strong>Valor: </strong>
                  {{ $format.moneyBr(sale.negotiation_value) }}
                </span>
                <span>
                  <strong>OBS: </strong>{{ sale.negotiation_comments }}
                </span>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-if="sale.notes">
          <v-row>
            <v-col>
              <h4>
                <v-icon left>mdi-text</v-icon>
                Observações
              </h4>

              <div class="d-flex flex-column ml-9">
                {{ sale.notes }}
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- <v-row v-if="sale.notes">
          <v-col>
            <h4>Observações</h4>
            {{ sale.notes }}
          </v-col>
        </v-row> -->
        <v-row>
          <v-col>
            <v-alert v-if="errorMessages" v-for="(error, index) in errorMessages" :key="index" dense outlined
              type="error">
              <div class="d-flex justify-space-between">
                <span>{{ error.message }}</span>

                <app-shortcut-btn v-if="error.shortcut_name" :name="error.shortcut_name" :params="sale.customer.id"
                  :query="{ customer_id: sale.customer.id }" @store="loadSale()" />
              </div>
            </v-alert>

            <v-alert v-if="sale.customer && sale.customer.alert_notes" border="left" type="error" dense outlined>
              {{ sale.customer.alert_notes }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn :disabled="countdown > 0" color="primary" @click="handleApproveSale()">
          Aprovar Venda

          <span v-if="countdown > 0"> ({{ countdown }}) </span>
        </v-btn>

        <v-btn text @click="dialog = false">Voltar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import saleValidator from "@/services/sale/saleValidator.js";

export default {
  data() {
    return {
      dialog: false,
      errorMessages: [],
      countdown: 0,
      stats: {
        net_overdue_value: {},
        to_pay_bill_receivables_totals: {},
      },
      sale: {
        negotiation_value: null,
        negotiation_comments: null,
        approved_by: {},
        id: null,
        company: {},
        salesman: {},
        customer: {},
        customer_stats: {},
        operation: {},
        sale_products: [],
        sale_bills: [],
        sale_payment_terms: [],
        account_transactions: [],
      },
    };
  },

  computed: {
    hasCustomer() {
      return this.sale.customer_id;
    },

    hasNegotiation() {
      return this.sale.has_negotiation;
    },

    toPayTotal() {
      if (this.hasCustomer) {
        return this.stats.to_pay_bill_receivables_totals.sum_value
      }
    },
    overdueTotal() {
      if (this.hasCustomer) {
        return this.stats.net_overdue_value.sum_due_value
      }
    },
  },

  methods: {
    async open(sale_id) {
      this.sale.id = sale_id;

      await this.loadSale(sale_id);

      this.dialog = true;

      this.startCountdown();
    },

    async loadSale(id) {
      var id = id || this.sale.id;

      this.$loading.start();

      await this.$http
        .show("sale/sale", id)
        .then(async (response) => {
          this.sale = response.sale;

          if (this.sale.customer_id) {
            await this.loadCustomer();
          }
          this.getErrorMessages();
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },

    async loadCustomer() {
      await this.$http
        .show("customer/customer", this.sale.customer_id)
        .then((response) => {
          this.stats = response.stats
          this.sale.customer = response.customer;
        });
    },

    handleApproveSale() {
      this.$loading.start();

      this.$http
        .update("sale/approve-sale", this.sale.id)
        .then((response) => {
          this.$emit("store");
          this.dialog = false;
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },

    startCountdown() {
      this.countdown = 5;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
        }
      }, 1000);
    },

    getErrorMessages() {
      this.errorMessages = saleValidator(this.sale);
    },
  },
};
</script>

<style></style>